
.load-wrapp {
    /* width: 100%;
    height: calc(100% - 100px); */
    /* margin: 0 10px 10px 0; */
    margin:auto;
    padding: 20px 20px 20px;
    text-align: center;
    /* background-color: rgba(73,151,229,.4); */
    /* background-color: rgba(73,151,229,.1); */
    /* position: absolute; */
    top: 100px;
    left: 0px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .load-wrapp p {padding: 0 0 20px;}
  .load-wrapp:last-child {margin-right: 0;}
  
  .line {
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 15px;
    /* background-color: #8b1b1b; */
    background-color: #2D0E94;
    margin: 10px;
    /* border: solid 5px #297F87; */
    border: solid 1px #A70082;
    z-index: 9;
  }
  
  .load-3 .line:nth-last-child(1) {animation: loadingC .6s .1s ease-in-out infinite;}
  .load-3 .line:nth-last-child(2) {animation: loadingC .6s .2s ease-in-out infinite;}
  .load-3 .line:nth-last-child(3) {animation: loadingC .6s .3s ease-in-out infinite;}
  
  @keyframes loadingC {
    0% {transform: translate(0,0);}
    50% {transform: translate(0,25px);}
    100% {transform: translate(0,0);}
  }
  