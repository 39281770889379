

.admin-memo {
    width: 100%;
    position: fixed;
    top: 40px;
    background: rgba(116, 116, 116, 0.5);
}

.admin-memo table{
    margin: 0px;
    width: 100%;
}

.admin-memo input {
    width: 49%;
    padding: 0px;
}

.admin-memo section {
    width: 100%;
    height: 75vh;
    display: flex;
}

/* ---- MEDIA QUERIES ---- */

@media (max-height:440px) {
    .admin-memo {
        top: 0px;
        height: 20vh;
    }
}

@media (max-height:299px) {
    .admin-memo textarea {
        height: 60vh;
    }
}

@media (max-height:200px) {
    .admin-memo textarea {
        height: 50vh;
    }
}