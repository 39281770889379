
.info-container{
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.data-header{
    width: 100%;
    border-bottom: 1px solid #4bdfec;
    display: flex;
	justify-content: space-around;

}

::-webkit-scrollbar {
    width: 10px;
  }

.display-matrix { /*--- displays items from selected database ---*/
    position: relative;
    min-height: 80%;
    max-height: 100%;
    width: 100%;
    margin: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.search-bar {
    width: 80vw;
    height: 30px;
    margin: 10px 20px 20px;
    display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: baseline;
    position: relative;
}

/* ------- MEDIA QUERIES ------- */

@media (max-width:550px) {
    .search-bar {width: 100%;}
    .search-bar input {
        width: 50px;
        padding: 0px;
    }
    
    .display-matrix{padding: 0px;}
}