.auth-container {
    border-radius: 8px;
    box-shadow: 15px 15px 20px rgba(73, 73, 73,.5);
    width: 400px;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    background-image: linear-gradient(97deg,#2D0E94 20%,#00c3d8 20%,rgba(0,0,0,.7)),url(download.png);
    background-repeat: no-repeat;

  }

  @media (max-width: 475px) {
      .auth-container {
          width: 80%;
          height: 300px;
      }
  }