

.App {
  width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(120deg,#ffffff 20%,rgba(150, 150, 150, .9)),url(abstract_refined.jfif);
  background-repeat: no-repeat;
  background-position: right top;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
}

h6,
h5,
h4,
h3,
h2,
h1 {    
    font-weight: 300;
    text-transform: uppercase;
    color: #555;
}
h4:hover,
h3:hover {
  background-color: #F9F871;
  transition: all 500ms;
  border-radius: 5px;
  cursor: pointer;
}

form {
  position: absolute;
  min-height: 100px;
  width: 180px;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  overflow: auto;
  opacity: 100;
  transition: all 150ms;
  z-index: 5;
}

textarea {
  width: 100%;
  background-color: #fff;
}

object {
  height: 100%;
  width: 100%;
}

i,
a {
  color: #FFB746;
  cursor: pointer;
}
i {
  font-weight: 600;
  font-size: 10px;
}

/* --- REUSABLE --- */
.selected {
  color: #FFB746;
  border-bottom: 1px solid #FFB746;
}

.hide {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0px;
}

.btn-selected,
.btn {
  background-color: #fff;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-selected {
  background-color: #297F87;
  color: #fff;
}

/* ------ MEDIA QUERIES -------*/

@media (max-width:550px) {
  h3 {font-size: 10px;}
  a {font-size: 10px;}
}

@media (max-width:288px) {
  a {font-size: 8px;}
}