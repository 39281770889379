
/* --- ENTIRE TABLE DISAPLEYED IN THIS CLASS --- */
table {
    min-width: 80vw;
    display: flex;
	flex-direction: column;
}
/* --------------------------------------------- */

/*  --- TABLE HEAD --- */
thead {
    position: sticky;
    text-align: left;
    top:0;
    background-color: #297F87;
    white-space: nowrap;
    z-index: 4;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

thead th:hover {
    background-color: #8b1b1b;
    transition: all 500ms;
}

/* -- TABLE HEAD COLUMN -- */
th {
    color: #fff;
    padding: 16px 24px;
    text-align: left;
}

/* -- TABLE DATA -- */
td {
    display: inline-block;
    padding: 14px 0px 14px 0px;
    font-weight: 600;
    text-decoration: none;
    font-size: 10px;
    text-align: left;
    overflow: hidden;
    margin-left: 2px;
}


/* --- ROWS --- */
tbody tr:nth-of-type(2n+1) {
    background-color: rgba(240, 240, 240, .5);
}

tr {
    width: 100%;
    height: 50px;
    display: flex;
	justify-content: space-around;
    margin-left: 20px;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 600;
    margin: 0px;
}

/* -- COLUMNS -- */
/* tbody tr td {
    min-width: 30px;
    height: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: none;
    justify-content: center;
    padding: 14px 0px 14px 0px;
} */

tbody tr:hover {
      background-color: #297F87;
      color: #fff;
      transition: all 500ms;
}

tr td:hover {
    background-color: #8b1b1b;
    cursor: pointer;
}