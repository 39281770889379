.links {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.links span {
    margin: auto;
    width: 100vw;
    border-top: 1px solid #4bdfec;
}

.link {
    margin: auto;
    color: #555;
    font-weight: bold;
}
