.header {
    height: 100px;
    width: 100vw;
    background-color: #fff;

    display: flex;
	justify-content: space-between;
	align-items: center;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
}

.logo {
    height: 50px;
    margin-left: 15px;
}

@media (max-height:500px) {
    .header {
        height: 50px;
    }
}